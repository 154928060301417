export const KnownExtensions = {
    image: [
        'png',
        'jpg',
        'gif',
    ],
    video: [
        'mp4',
        'mkv',
    ]
}